<template>
  <div>
    <b-card title="NUEVO AJUSTE POSITIVO">
      <!-- <hr class="hr"> -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="TIPO DE AJUSTE:"
                label-for="tipoAjuste"
                label-cols="12"
                label-cols-lg="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Observacion"
                  rules="required"
                >
                  <b-form-input
                    size="sm"
                    placeholder="Observacion"
                    id="Observacion"
                    v-model="ajuste.observacion"
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <hr class="hr" />

          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Código (Esc):"
                    label-for="codigo"
                    label-cols="12"
                    label-cols-lg="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MaximizeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="codigo"
                        v-model="codigo"
                        @keyup.enter="buscarCodigo()"
                        onClick="this.select();"
                        size="sm"
                        placeholder="Digite o Escanee el código"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    size="sm"
                    block
                    @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
                  >
                    BUSCAR ARTICULO (F9)
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="12">
              <ag-grid-vue
                :gridOptions="gridOptions"
                class="ag-theme-balham ag-grid-tableAjuste"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                @grid-ready="onGridReady"
                :rowData="ajuste.detalle"
                :suppressRowClickSelection="true"
                colResizeDefault="shift"
                :animateRows="true"
                :floatingFilter="false"
                :rowClassRules="rowClassRules"
                @rowClicked="onRowClicked($event)"
                :alwaysShowVerticalScroll="true"
                @first-data-rendered="onFirstDataRendered"
                :getRowStyle="getRowStyle"
                :pinnedBottomRowData="pinnedBottomRowData"
                @cell-value-changed="onCellValueChanged"
                @cell-key-down="onCellKeyDown"
              >
              </ag-grid-vue>
            </b-col>
          </b-row>

          <b-row align-h="end">
            <b-col cols="12" md="4">
              <b-form-group style="text-align: end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="dark"
                  class="mr-1"
                  @click.prevent="validar"
                >
                  REALIZAR AJUSTE
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  disabled
                  size="sm"
                  variant="outline-dark"
                >
                  LIMPIAR
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay
        :show="submitted"
        no-wrap
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      />
      <ModalSelectArticulo
        ref="ModalSelectArticuloRef"
        @selectItemArticulo="selectItemArticulo"
      />
    </b-card>
  </div>
</template>
  
  <script>
import axios from "axios";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardGroup,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSpinbutton,
  BFormRadio,
  BFormCheckbox,
  BTable,
  BInputGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge,
  BForm,
  BOverlay,
  BImg,
  BInputGroupAppend,
  VBTooltip,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { required } from "@validations";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "ag-grid-vue";
import ModalSelectArticulo from "@/modules/punto-venta/components/ModalSelectArticulo.vue";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BCardFooter,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    Cleave,
    BFormSpinbutton,
    BFormRadio,
    BFormCheckbox,
    BTable,
    VuePerfectScrollbar,
    BInputGroup,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    BImg,
    BInputGroupAppend,
    VBTooltip,
    AgGridVue,
    BInputGroupPrepend,
    ModalSelectArticulo,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // modal articulo
      item: [],
      // table
      required,
      loading: false,
      metodoPagoItems: [],

      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: false,
      },
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "PRODUCTO",
          field: "descripcion",
          filter: false,
          autoHeight: true,
          wrapText: true,
          minWidth: 200,
        },
        {
          headerName: "",
          field: "info",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          filter: false,
          valueFormatter: cantidadNumberFormatter,
          valueParser: cantidadParser,
          minWidth: 150,
          maxWidth: 150,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "COSTO",
          field: "precio_unitario",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
          valueFormatter: precioNumberFormatter,
          valueParser: precioParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "SUBTOTAL",
          field: "subTotal",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: numberFormatter,
          editable: function (param) {
            if (param.node.rowPinned) {
              if (param.node.data.edit) {
                return true;
              }
              return false;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
          valueGetter: (param) => {
            if (!param.node.rowPinned) {
              param.data.subTotal =
                param.data.cantidad * param.data.precio_unitario;
              return param.data.subTotal;
            }
            return param.node.data.subTotal;
          },
        },
        {
          headerName: "",
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            } else {
              return `<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">X</button>`;
            }
          },
        },
      ],
      // end grid

      submitted: false,
      // facturas
      ajuste: {
        detalle: [],
        subTotal: 0,
        observacion: "",
      },
      errores: [],
      codigo: "",
    };
  },
  created: function () {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  watch: {},
  computed: {
    pinnedBottomRowData() {
      return [
        {
          info: "TOTAL Bs",
          subTotal: this.calcularTotal(),
          bold: true,
        },
      ];
    },
  },
  methods: {
    onkey(event) {
      if (event.key == "F9") {
        this.$refs.ModalSelectArticuloRef.focusBuscarArticulo();
      }
      if (event.key == "Escape") {
        document.getElementById("codigo").focus();
        document.getElementById("codigo").select();
      }
    },
    // VALIDAR FORMULARIO
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.ajuste.detalle.length > 0) {
            this.submitted = true;
          } else {
            this.$bvToast.toast(" AGREGAR POR LO MENOS UN ARTICULO. ", {
              title: "ERROR!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: false,
            });
          }
        } else {
          this.$bvToast.toast(" COMPLETE LOS CAMPOS. ", {
            title: "ERROR!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      });
    },

    async registrar() {},

    // remover de grid
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    remover(index) {
      this.ajuste.detalle.splice(index, 1);
    },
    // calculos de totales
    calcularTotal() {
      let resultado = 0;
      for (let index = 0; index < this.ajuste.detalle.length; index++) {
        resultado = resultado + this.ajuste.detalle[index].subTotal;
      }
      resultado = resultado.toFixed(2);
      this.ajuste.subTotal = parseFloat(resultado);
      return resultado;
    },

    onCellValueChanged(event) {},

    // grid
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },

    // FORMATOS DE TEXTO EN MAUSCULAS Y DECIMALES
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },

    // limpiar formulario
    limpiarForm() {
      (this.ajuste = {
        detalle: [],
        montoTotal: 0,
        observacion: "",
      }),
        (this.codigo = "");
      this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
      this.$refs.simpleRules.reset();
    },

    // codigo de barra articulos
    async buscarCodigo() {
      if (this.codigo.length > 0) {
        await axiosIns
          .get(`articulos/filtrar?codigo=${this.codigo}`)
          .then((res) => {
            let producto = res.data.articulo;
            if (producto) {
              this.selectItemArticulo(producto, false);
            } else {
              this.$bvToast.toast(" CODIGO DE ARTICULO NO EXISTE. ", {
                title: "ADVERTENCIA!",
                variant: "warning",
                toaster: "b-toaster-top-center",
                solid: false,
              });
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }
          })
          .catch((err) => {
            this.$bvToast.toast(" CODIGO DE ARTICULO NO EXISTE. ", {
              title: "ADVERTENCIA!",
              variant: "warning",
              toaster: "b-toaster-top-center",
              solid: false,
            });
            document.getElementById("codigo").focus();
            document.getElementById("codigo").select();
            console.log(err);
          });
      }
    },
    // encuentra articulo
    encuentraArticulo(id, estado) {
      var sw = 0;
      for (let i = 0; i < this.ajuste.detalle.length; i++) {
        if (this.ajuste.detalle[i].id == id) {
          this.ajuste.detalle[i].cantidad++;
          sw = true;
          if (estado) {
            setTimeout(() => {
              this.focusAgridCantidad(i);
            }, 500);
          } else {
            setTimeout(() => {
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }, 300);
          }
        }
      }
      return sw;
    },
    // Emit de select articulo
    selectItemArticulo(producto, estado) {
      if (producto) {
        if (!this.encuentraArticulo(producto.id, estado)) {
          this.ajuste.detalle.push({
            id: producto.id,
            cantidad: 1,
            codigo: producto.codigo,
            descripcion: producto.descripcion,
            precio_unitario: producto.precio_unitario,
            subTotal: producto.precio_unitario * 1,
          });
          if (estado) {
            setTimeout(() => {
              this.focusAgrid();
            }, 500);
          } else {
            setTimeout(() => {
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }, 300);
          }
        }
      } else {
        this.$bvToast.toast(" POR FAVOR SELECCIONE UN ARTICULO ", {
          title: "ADVERTENCIA  !",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: false,
        });
      }
      this.gridApi.redrawRows();
    },
    focusAgrid() {
      var cellrows = this.gridApi.getDisplayedRowCount();
      this.gridApi.setFocusedCell(cellrows - 1, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: cellrows - 1,
        colKey: "cantidad",
      });
    },
    focusAgridCantidad(i) {
      this.gridApi.setFocusedCell(i, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: i,
        colKey: "cantidad",
      });
    },
    // key pres
    onCellKeyDown(params) {
      if (params.event) {
        if (params.event.key == "Enter") {
          setTimeout(() => {
            document.getElementById("codigo").focus();
          }, 200);
        }
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
function cantidadNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function cantidadParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.cantidad;
  }
}

function precioParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.precio_unitario;
  }
}
function precioNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/components/agGridStyle.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
.table-active > td {
  background-color: #ff7676 !important;
  color: white;
}
.table-active > td > button {
  background-color: #ff7676 !important;
  color: white;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100 - 26.3rem);
}
.my-0-5 {
  margin: 0.5rem !important;
  margin-left: 0px !important;
}
#card-pv {
  .card-body {
    padding: 0.8rem;
  }
}
.hr {
  margin-top: 0.1rem;
  margin-bottom: 0.25rem;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}
[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.box {
  height: 9rem;
}
.box img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: cover;
  // object-fit: contain;
}
.custom-select:disabled {
  color: #6e6b7b;
  font-weight: 500;
}
</style>